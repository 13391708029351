import { createApp } from 'vue'
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'
import App from './App.vue'
import router from '@/router'
import '@/assets/styles/base.css'
import '@/assets/styles/main.css'
import '@/assets/iconfont/iconfont.css'
import * as ElementPlusIconsVue from '@element-plus/icons-vue'
// import  '@/utils/appendScript'
if (process.env.NODE_ENV === 'production') {  
  // 线上
  const gtagScript = document.createElement('script');  
  gtagScript.async = true;  
  gtagScript.src = 'https://www.googletagmanager.com/gtag/js?id="G-5WRDYFCDED"';  
  gtagScript.onload = () => {  
    window.dataLayer = window.dataLayer || [];  
    function gtag() { dataLayer.push(arguments); }  
    gtag('js', new Date());  
    gtag('config', 'G-5WRDYFCDED');  
  };  
  document.head.appendChild(gtagScript);  
} else if (process.env.NODE_ENV === 'development' || process.env.NODE_ENV == 'test') { 
  // 测试
  const gtagScript = document.createElement('script');  
  const elScript = document.createElement('script');  
  gtagScript.async = true;  
  gtagScript.src = `https://www.googletagmanager.com/gtag/js?id="G-W0B9PZP9YL"`;  
  gtagScript.onload = () => {  
    window.dataLayer = window.dataLayer || [];  
    function gtag() { dataLayer.push(arguments); }  
    gtag('js', new Date());  
    gtag('config', 'G-W0B9PZP9YL');  
  };  
  elScript.textContent = `
  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  gtag('js', new Date());
  gtag('config','G-W0B9PZP9YL');
  `
  document.head.appendChild(gtagScript);
  document.head.appendChild(elScript);
}  

const app = createApp(App)
for (const [key, component] of Object.entries(ElementPlusIconsVue)) {
    app.component(key, component)
  }
app.use(ElementPlus)

app.use(router)

app.mount('#app')